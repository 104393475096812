import Vue from 'vue';
import Vuex from 'vuex';

import currentTournament from './modules/currentTournament';
import currentTournamentLol from './modules/currentTournamentLol';
import bracket from './modules/bracket';
import currentUser from "./modules/CurrentUser";

Vue.use(Vuex);
export default new Vuex.Store({
    modules:{
        currentTournament,
        currentTournamentLol,
        bracket,
        currentUser,
    }
});