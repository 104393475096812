<template>
    <div class="mt-2 px-lg-5 mb-md-4 w-100" id="faq">
        <h2 class="faq-title text-center mb-2" dir="rtl">الأسئلة الشائعة</h2>
        <FaqItem v-for="(faqItem, i) in faqList" :key="i"
            :faqItem="faqItem"
        />
    </div>
</template>

<script>
    import FaqItem from './FaqItem.vue';
    export default {
        name: "FaqList",
        components:{
            FaqItem
        },
        data(){
            return {
                faqList:[
                    {
                        question: "كيف يمكن تسجيل فريقي في البطولة؟",
                        answer: `يمكن تسجيل فريقك في البطولة عبر 3 مراحل:
                                <br>
                                1 - إنشاء حساب من طرف كل أعضاء الفريق داخل الموقع <a href="https://youtu.be/15q-SAFSBVI" target="_blank">(باتباع الخطوات التالية)</a>.
                                <br>
                                2 - على قائد الفريق إنشاء الفريق الخاص بكم داخل الموقع <a href="https://youtu.be/O-0hvV8WwUY" target="_blank">(باتباع الخطوات التالية)</a>.
                                <br>
                               3 - تسجيل فريقكم في البطولة من طرف قائد الفريق.
                               <br>
                                4 - و أخيرا الإنضمام ل <a href="https://discord.gg/3QEPMZg2Jy" target="_blank">Server Discord</a> الخاص بالبطولة.`
                    },
                    {
                        question: "اين ستقام البطولة؟",
                        answer:`ستقام التصفيات عن بعد، و النهائي الكبير في مدينة الدار البيضاء بالضبط في صالة الألعاب <a href="https://goo.gl/maps/NFhKb79iNky8WYLBA" target="_blank">E- Blue Gaming Center</a>`
                    },
                    {
                        question: "هل يمكن استبدال اللاعبين خلال البطولة؟",
                        answer: `لا، بمجرد بدء البطولة، يجب على الفرق الحفاظ على تركيبة اللاعبين الذين سجلوا أصلاً. سيكون اللاعبون الأربعة المسجلون منذ بداية البطولة الوحيدين المسموح لهم بالمشاركة في مباريات البطولة. لن يُسمح باستبدال أي لاعب بعد بدء البطولة لضمان العدالة ونزاهة المنافسة.`
                    },
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
    .faq-title{
        color:#E62A41 !important;
        font-family: "Tajawal" !important;
        font-weight: 600;
    }
</style>