<template>
    <div class="participate-card-wrapper" v-if="(!tournamentStarted && registrationStatus)">
        <div class="participate-card-container ">
        
            <div class="w-100" v-if="!isAuthenticated">
                <div class="d-flex justify-content-start w-100">
                    <div class="text-center d-flex flex-column justify-content-center align-items-center" style="background-color:#F7F3FF; width: 64px; border-left:4px red solid;">
                        <img :src="game == 'lol' ? require('@/assets/lol-btn.png') : require('@/assets/fc24-btn.png')" height="42px"/>
                    </div>
                    <button class="participate-btn px-4"
                        data-toggle="modal" data-target="#loginModal"
                        @click="participateAction()"
                    >
                        S'inscrire <span style="font-size: 12pt !important;">{{ game == "lol" ? "(LOL)" : "(EA FC24)" }}</span>
                    </button>
                </div>
                
            </div>
            <div class="w-100" v-else>
                <div class="d-flex justify-content-start w-100">
                    <div class="text-center d-flex flex-column justify-content-center align-items-center" style="background-color:#F7F3FF; width: 64px; border-left:4px red solid;">
                        <img :src="game == 'lol' ? require('@/assets/lol-btn.png') : require('@/assets/fc24-btn.png')" height="42px"/>
                    </div>
                    <button class="participate-btn px-4" data-toggle="modal" data-target="#lgame-participation-pop-up" @click="participateAction()"
                        v-if="!isParticipant"
                    >
                        S'inscrire <span style="font-size: 12pt !important;">{{ game == "lol" ? "(LOL)" : "(EA FC24)" }}</span>
                    </button>
                    <button class="participate-btn px-4" data-toggle="modal"            data-target="#lgame-participation-pop-up"
                        @click="cancelParticipateAction(gameId)"
                        v-else
                    >
                        Se desinscrire <span style="font-size: 12pt !important;">{{ game == "lol" ? "(LOL)" : "(EA FC24)" }}</span>
                    </button>
                </div> 
                <h6 class="font-weight-bold text-center" style="color:#E30923" v-if="isParticipant">( Vous êtes inscrit )</h6>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
 import 'moment';
import moment from 'moment';
import axios from "axios";
    export default {
        name: 'ParticipateCard',
        props:{
            game:{
                type: String
            },
            gameId:{
                type: Number,
            }
        },
        data(){
            return {

                participantsSize: 0,
                startDate: moment().add(7, 'days').valueOf(),
                today: moment().valueOf(),
                countDown:{
                    days: null, 
                    hours: null, 
                    minutes: null, 
                    seconds: null, 
                },
                diffTime: null,
                interval: 1000,
                duration: null,
                TYPE_PLAYER: 1,
                TYPE_TEAM: 2,
            
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return this.game === "lol" ? !!this.$store.state.currentTournamentLol.tournamentItem : !!this.$store.state.currentTournament.tournamentItem;
                },
                set(val) {
                    return val;
                }
            },
            startDate_comp:{
                get(){
                    if(this.isLoaded){
                        const date = this.game === "lol" ? this.$store.state.currentTournamentLol.tournamentItem.start_date : this.$store.state.currentTournament.tournamentItem.start_date;
                        const time = this.game === "lol" ? this.$store.state.currentTournamentLol.tournamentItem.start_time : this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment(date + ' ' + time, 'DD-MM-YYYY HH:mm').valueOf();
                    }
                    return null;
                }
                
            },
            isAuthenticated: {
                get() {
                    return this.$store.state.currentUser.isAuthenticated;
                }
            },
            registrationStatus: { // true:  open, false: closed
                get() {
                    if (this.isLoaded) {
                        return this.game === "lol" ? this.$store.state.currentTournamentLol.tournamentItem.settings.registration_status : this.$store.state.currentTournament.tournamentItem.settings.registration_status;
                    }
                }
            },
            registrationEnds: {
                get() {
                    if (this.isLoaded) {
                        moment.locale('fr');
                        const dateTime = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.start_date + ' ' + this.$store.state.currentTournamentLol.tournamentItem.start_time
                            : this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment(dateTime, 'DD-MM-YYYY HH:mm').format('ddd Do, MMM  [à] hA');
                    }
                }
            },
            isPastTournament: {
                get() {
                    if (this.isLoaded) {
                        const dateTime = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.end_date + ' ' + this.$store.state.currentTournamentLol.tournamentItem.end_time
                            : this.$store.state.currentTournament.tournamentItem.end_date + ' ' + this.$store.state.currentTournament.tournamentItem.end_time;
                        console.log(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                        return moment().isAfter(moment(dateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            checkInStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.start_date + ' ' + this.$store.state.currentTournamentLol.tournamentItem.start_time
                            : this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        const checkInStartsIn = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.settings.check_in_starts
                            : this.$store.state.currentTournament.tournamentItem.settings.check_in_starts;
                        const checkinStartTime = moment(startDateTime, 'DD-MM-YYYY HH:mm').subtract(checkInStartsIn, 'minutes').toDate();
                        return moment().isBefore(moment(startDateTime, 'DD-MM-YYYY HH:mm')) && moment().isAfter(moment(checkinStartTime));
                    }
                }
            },
            tournamentStarted: {
                get() {
                    if (this.isLoaded) {
                        const startDateTime = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.start_date + ' ' + this.$store.state.currentTournamentLol.tournamentItem.start_time
                            : this.$store.state.currentTournament.tournamentItem.start_date + ' ' + this.$store.state.currentTournament.tournamentItem.start_time;
                        return moment().isSameOrAfter(moment(startDateTime, 'DD-MM-YYYY HH:mm'));
                    }
                }
            },
            participantType: {
                get() {
                    if (this.isLoaded) {
                        const size = this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.settings.play_mode.size
                            : this.$store.state.currentTournament.tournamentItem.settings.play_mode.size;
                        return size > 1 && size !== 0 ? this.TYPE_TEAM : this.TYPE_PLAYER;
                    }
                }
            },
            tournamentSize: {
                get() {
                    if (this.isLoaded) {
                        return this.game == "lol"
                            ? this.$store.state.currentTournamentLol.tournamentItem.settings.size
                            : this.$store.state.currentTournament.tournamentItem.settings.size;
                    }

                }
            },
            participantCount: {
                get() {
                    if (this.isLoaded) {
                      const storeModule = this.game == "lol" ?  this.$store.state.currentTournamentLol :  this.$store.state.currentTournament;
                        return this.participantType === this.TYPE_PLAYER ?
                            storeModule.tournamentItem.participants_players.length :
                            storeModule.tournamentItem.participants_teams.length;
                    }
                    return 0;
                }
            },
            isParticipant: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.game == "lol"
                                ? this.$store.state.currentTournamentLol.playerIsParticipant
                                : this.$store.state.currentTournament.playerIsParticipant;
                        else
                            return this.game == "lol" ? this.$store.state.currentTournamentLol.playerTeamIsParticipant : this.$store.state.currentTournament.playerTeamIsParticipant ;
                    }
                    return false;
                }
            },
            isParticipantCheckedIn: {
                get() {
                    if (this.isLoaded) {
                        if (this.participantType === this.TYPE_PLAYER)
                            return this.$store.state.currentTournament.playerIsCheckedIn;
                        else
                            return this.$store.state.currentTournamentLol.playerTeamIsCheckedIn;
                    }
                }
            },
            hasCustomFields: {
                get() {
                    if (this.isLoaded) {
                        const customFields = this.game === "lol" ? this.$store.state.currentTournamentLol.tournamentItem.settings.custom_fields : this.$store.state.currentTournament.tournamentItem.settings.custom_fields;
                        return customFields !== null && customFields.length > 0;
                    }
                }
            },
            isLoadingAction: {
                get() {
                    if (this.isLoaded) {
                        return this.game == "lol" ? this.$store.state.currentTournamentLol.isLoadingAction : this.$store.state.currentTournament.isLoadingAction;
                    }
                }
            },
            teamParticipatedId: {
                get() {
                    if (this.isLoaded) {
                        return this.game == "lol" ? this.$store.state.currentTournamentLol.teamParticipatedId : this.$store.state.currentTournament.teamParticipatedId;
                    }
                }
            },
            isUserAuth: {
                get() {
                    if (this.isLoaded) {
                        return this.$store.state.currentUser.isAuthenticated;
                    }
                }
            },
          storeModule:{
              get(){
                return this.game == "lol" ? "currentTournamentLol" : "currentTournament";
              }
          }
    },
    watch: {
        isUserAuth(newVal, oldVal) {
            if (oldVal === false && newVal === true && !this.isParticipant) {
                if(this.game === "lol"){
                  this.$store.dispatch('currentTournamentLol/checkParticipation');
                }else{
                  this.$store.dispatch('currentTournament/checkParticipation');
                }
            }
        },
        startDate_comp(newVal, oldVal){
            if(oldVal !== newVal && newVal !== null){
                this.initCountdown();
            }
        }
    },
         methods: {
            participateAction() {
                // participate current auth user

                if (this.isAuthenticated) {
                    this.$store.dispatch(this.storeModule+'/triggerLoading', true);
                    if (this.participantType === this.TYPE_TEAM) {
                        this.$store.dispatch('currentTournamentLol/getCurrentPlayerTeams');
                        this.$emit('init-pop-up', {
                            type: 'teams',
                            title: "Choisir une équipe",
                            message: "Choisir une équipe parmis vous equipes pour participer a cette tournoi:",
                            withOptions: false,
                            popUpDispatch: 'currentTournamentLol/setParticipantTeam',
                            game: "lol"
                        });
                    }
                    if (this.participantType === this.TYPE_PLAYER) {
                        if (this.hasCustomFields) {
                            this.$emit('init-pop-up', {
                                type: 'custom',
                                withOptions: true,
                                title: "Formulaire d'inscription",
                                message: 'Merci de remplire les champs suivants pour participer au tournoi',
                                options: {
                                    mainAction: 'Participer',
                                    secondAction: 'Annuler'
                                },
                                popUpDispatch: 'currentTournament/setParticipant',
                            });
                        } else {
                            this.$store.dispatch('currentTournament/setParticipant')
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        }
                    }
                } else {
                    this.$root.$emit('show-login', 'check_participation');
                }

            },
            cancelParticipateAction() {
                this.$store.dispatch(this.storeModule+'/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    console.log('cancelling player participation')
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: 'currentTournament/cancelParticipation',
                    });
                    /*this.$store.dispatch('currentTournament/cancelParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre participation ?',
                        popUpDispatch: 'currentTournamentLol/cancelTeamParticipation'
                    });
                    console.log('cancelling team participation')
                    /*this.$store.dispatch('currentTournament/cancelTeamParticipation')
                        .then(() => {
                            this.isLoadingAction = false;
                        });*/
                }

            },
            checkPlayerIn() {
                this.$store.dispatch(this.storeModule+'/checkPlayerIn');
            },
            checkParticipantIn() {
                this.$store.dispatch(this.storeModule+'/triggerLoading', true);
                if (this.participantType === this.TYPE_TEAM) {
                    this.$store.dispatch(this.storeModule+'/checkTeamIn', {
                        teamId: this.teamParticipatedId,
                        checkinValue: true,
                    }).then(() => {
                        this.$store.dispatch(this.storeModule+'/triggerLoading', false);
                    });
                }
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$store.dispatch(this.storeModule+'/checkPlayerIn', {
                        checkinValue: true,
                    })
                        .then(() => {
                            this.$store.dispatch(this.storeModule+'/triggerLoading', false);
                        });
                }
            },
            cancelCheckIn() {
                //only team case done
                this.$store.dispatch(this.storeModule+'/triggerLoading', true);
                if (this.participantType === this.TYPE_PLAYER) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: this.storeModule+'/checkPlayerIn',
                        dispatchData: {
                            checkinValue: false,
                        }
                    })
                }
                if (this.participantType === this.TYPE_TEAM) {
                    this.$emit('init-pop-up', {
                        type: 'warning',
                        withOptions: true,
                        title: 'Attention !',
                        message: 'Etes-vous sur de vouloir annuler votre checkin ?',
                        popUpDispatch: this.storeModule+'/checkTeamIn',
                        dispatchData: {
                            teamId: this.teamParticipatedId,
                            checkinValue: false,
                        }
                    });
                }
            },
            viewBracket() {
                this.$emit('view-bracket');
            },
            initCountdown(){
                this.diffTime = this.startDate_comp - this.today;
                this.duration = moment.duration(this.diffTime, 'milliseconds');
                setInterval(() => {
                    this.duration = moment.duration(this.duration - this.interval, 'milliseconds');
                    this.countDown.days = this.duration.days() < 10 ? '0' + this.duration.days() : this.duration.days();
                    this.countDown.hours = this.duration.hours() < 10 ? '0' + this.duration.hours() : this.duration.hours();
                    this.countDown.minutes = this.duration.minutes() < 10 ? '0' + this.duration.minutes() : this.duration.minutes();
                    this.countDown.seconds = this.duration.seconds() < 10 ? '0' + this.duration.seconds() : this.duration.seconds();
                    this.countdownInitialized = true;
                }, this.interval);
            }
        },
        mounted(){
            

        }
    }
</script>

<style scoped>
.participate-card-container{
   
}
.card-title{
    font-family: 'Gemunu Libre ExtraBold';
    font-size: 26pt;
}
.number-item{
    font-family: 'Long-shot';
    font-size: 56pt;
    text-transform: uppercase;
}
.unit-item{
    font-family: 'Long-shot';
    font-size: 28pt;
    text-transform: uppercase;
}
.countdown-title{
    font-family: 'Gemunu Libre Bold';
}
.participants-status{
    font-family: 'Gemunu Libre Bold';
}
.participate-btn{
    border:unset;
    background: #E30923;
    outline: none;
    color: white;
    background-size: 100% 100%;
    height: 48px;
    font-family: 'Gemunu Libre Bold';
    font-size: 20pt;
    text-transform: uppercase;
    font-weight: 700;
}
.participate-btn:hover{
    transform: translateX(1px);
    transition: scale .1s linear;
}


</style>