<template>
  <div class="lgame-participation-pop-up-container-wrapper modal fade" id="lgame-participation-pop-up"
       data-backdrop="static"
       @keyup.esc="closeModal"
       tabindex="-1" role="dialog" aria-labelledby="lgameParticipationPopUpLabel" aria-hidden="true"
  >
    <div class="lgame-participation-pop-up-container modal-dialog modal-dialog-centered p-md-2 mx-auto" role="document">
      <button ref="participateBtnClose" data-dismiss="modal" hidden
              @click="closeModal"
      ></button>
      <div class="lgame-participation-pop-up-content-wrapper modal-content"
           :style="{backgroundColor: colors[popUpType]}"
      >
        <div class="lgame-participation-pop-up-content p-3 p-md-4">
          <div class="lgame-participation-pop-up-header mb-2" v-if="popUpType === 'teams'">
            <h4 class="pop-up-title" :style="{color: colors[popUpType]}" v-if="currentPlayerTeams.length > 0">{{popUpTitle}}</h4>
          </div>
          <div class="lgame-participation-pop-up-header mb-2" v-else>
            <h4 class="pop-up-title" :style="{color: colors[popUpType]}">{{popUpTitle}}</h4>
          </div>
          <div class="lgame-participation-pop-up-dynamic-content my-2 px-2">
            <slot name="dynamic">
              <div v-if="popUpType === 'warning'">
                <p class="lgame-participation-pop-up-message-body">{{messageBody}}</p>
              </div>
              <div class="my-2" v-if="popUpType === 'custom'">
                <p class="lgame-participation-pop-up-message-body">{{messageBody}}</p>
                <div class="custom-fields-container">
                  <div class="d-flex flex-column justify-content-start align-items-start mb-2 w-100"
                       v-for="field in customFields" :key="field">
                    <div>
                      <p class="custom-field-label m-0">{{field}}: </p>
                    </div>
                    <div class="custom-field-input-container w-100">
                      <input type="text" class="custom-field-input px-2"
                             maxlength="36"
                             v-model="customFieldsValue[field]"
                             required
                             @focusin="fieldFocusIn(field)"
                      />
                    </div>
                    <span class="span-error" v-if="customFields_emptyInputs[field]">Champs requis</span>
                  </div>
                </div>
              </div>
              <div v-if="popUpType === 'teams'">
                <p class="lgame-participation-pop-up-message-body text-justify" v-if="currentPlayerTeams.length>0">{{messageBody}}</p>
                <div class="player-teams-container py-1 pr-2 w-100">
                  <div class="teams-loading d-flex justify-content-center align-items-center h-100"
                       v-if="fetchingTeams">
                    <lgame-loader-comp></lgame-loader-comp>
                  </div>
                  <div v-else class="w-100 h-100">
                    <div v-if="currentPlayerTeams && !showCustomFields" class="w-100 h-100">
                      <div class="player-teams-empty-container d-flex flex-column justify-content-center align-items-center w-100 h-100" v-if="currentPlayerTeams.length === 0">
                        <h5>Vous n'avez pas une équipe d'au moins 5 membres dans le jeu {{gameName}}</h5>
                        <div class="w-100">
                          <h6>Découvrez comment créer votre propre équipe </h6>
                          <div class="w-100">
                            <iframe calss="w-75 w-md-100" src="https://www.youtube.com/embed/O-0hvV8WwUY" title="Creation d&#39;equipe - Lgame.gg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          </div>
                        </div>
                        <button class="pop-up-button btn-new-team px-3 px-md-4" @click="addNewTeam">Créer une équipe</button>
                      </div>
                      <div class="player-team-item-container mb-2 w-100" v-for="team in currentPlayerTeams" :key="team.id"
                           @click="teamParticipate(team)"
                      >
                        <div class="player-team-item d-flex justify-content-between align-items-center w-100">
                          <div class="d-flex justify-content-start align-items-center m-0 h-100">
                            <img
                                alt=""
                                class="team-item-avatar mr-3"
                                width="72px"
                                height="100%"
                                :src="teamAvatarDir+team.avatar"
                                @error.once="imageNotFound"
                            />
                            <h5 class="team-item-name m-0">{{team.team_name}}</h5>
                          </div>
                          <div class="d-flex justify-content-start align-items-center h-100">
                            <div class="text-participate-by">
                              <p class="m-0 text-muted mr-2">Participer avec cette équipe</p>
                            </div>
                            <button class="team-item-btn-participate"
                                    @click="teamParticipate(team)"
                            >
                              <!--:style="{backgroundColor: colors[popUpType]}"-->
                              <img
                                  alt=""
                                  class="img-arrow-participate"
                                  :src="arrowParticipate"
                                  style="fill: red"
                                  width="40%"
                                  height="40%"
                              >
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="custom-fields-container" v-if="showCustomFields">
                      <div class="d-flex flex-column justify-content-start align-items-start mb-2 w-100"
                           v-for="i in selectedTeam.size" :key="'team'+i">
                        <div class="d-flex flex-column justify-content-start align-items-start mb-2 w-100"
                             v-for="field in customFields" :key="field" v-if="field !== 'pollAnswer'"
                        >
                          <div>
                            <p class="custom-field-label m-0">{{field + ` (Participant ${i})`}}: </p>
                          </div>
                          <div class="custom-field-input-container w-100">
                            <input type="text" class="custom-field-input px-2"
                                   maxlength="36"
                                   v-model="customFieldsValue[`${field} P${i}`]"
                                   required
                                   @focusin="fieldFocusIn(`${field} P${i}`)"
                            />
                          </div>
                          <span class="span-error" v-if="customFields_emptyInputs[`${field} P${i}`]">Champs requis</span>
                        </div>
                      </div>
                      <!-- for xiaomi tournament -->
                      <div class="poll-container p-1 p-lg-2" v-if="tournamentId === 130 ">
                        <p class="custom-field-label m-0 font-weight-bold text-center" dir="auto">أين رأيت إعلان البطولة لأول مرة؟</p>
                        <div class="streamer-qst-container d-flex justify-content-start align-items-center mb-2" v-for="streamer in streamers" :key="streamer">
                          <input class="radio-btn" type="radio" :id="streamer" :value="streamer" v-model="pollAnswer" @change="customFields_emptyInputs['pollAnswer']=false"/>
                          <label class="radio-btn m-0 ml-2" :for="streamer" dir="auto">{{ streamer !== 'Autres' ? 'عند'+` ${streamer}` : streamer }}</label>
                        </div>
                        <div class="d-flex flex-column justify-content-start align-items-start"
                            v-if="pollAnswer === 'Autres'"
                        >
                          <input class="custom-field-input px-2" type="text" id="customAnswer"
                                placeholder="أدخل إجابتك هنا"
                                v-model="pollCustomAnswer" required
                                @focusin="customFields_emptyInputs['customAnswer'] = false"
                          />

                          <span class="span-error"
                                v-if="customFields_emptyInputs['customAnswer']">
                                                        Champs requis
                                                    </span>
                        </div>
                        <span class="span-error"
                              v-if="customFields_emptyInputs['pollAnswer']">Champs
                                                requis</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="lgame-participation-pop-up-footer d-flex flex-column align-items-end mt-2">
            <div class="horizontal-line my-2" :style="{backgroundColor: colors[popUpType]}"></div>
            <div v-if="isWithOptions">
              <button class="pop-up-button-cancel px-2 px-md-4"
                      @click="abortAction">
                {{actionsOptions.secondAction}}
              </button>
              <button class="pop-up-button px-3 px-md-4"
                      @click="acceptAction"
                      :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                      @mouseover="buttonHover = true" @mouseleave="buttonHover=false">
                {{actionsOptions.mainAction}}
              </button>
            </div>
            <div v-else>
              <button class="pop-up-button px-3 px-md-4"
                      @click="acceptAction"
                      :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                      @mouseover="buttonHover = true" @mouseleave="buttonHover=false"
                      v-if="popUpType === 'custom'">
                Participer
              </button>
              <button class="pop-up-button px-2 px-md-4"
                      @click="abortAction"
                      data-toggle="modal" data-target="#lgame-participation-pop-up"
                      :style="{backgroundColor: buttonHover ? getRGBA(colors[popUpType]):colors[popUpType]}"
                      @mouseover="buttonHover = true" @mouseleave="buttonHover=false"
                      v-else>
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import imageArrow from "@/assets/arrow_next.svg"

export default {
    name: "LgameParticipatePopUp",
    
    props: {
        popUpType: {
            type: String,
            default: 'warning'
        },
        title: {
            type: String,
            default: ''
        },
        popUpMessageBody: {
            type: String,
            default: '',
        },
        popUpDispatch: {
            type: String,
            default: '',
        },
        dispatchData: {
            type: Object,
            default() {
                return null;
            }
        },
        withOptions: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default() {
                return {
                    mainAction: 'Oui',
                    secondAction: 'Non',
                }
            }
        },
        game: {
          type: String,
          default: null,
        }
    },
    data() {
        return {
            definedTypes: ['warning', 'custom', 'teams'],
            colors: {
                custom: '#1890ff',
                warning: '#FFCC00',
                teams: '#fc0036',
            },
          buttonHover: false,
          //customFieldsValue: {},
          arrowParticipate: imageArrow,
          teamParticipationDone: false,
          showCustomFields: false,
          popUpTitle: this.title,
          messageBody: this.popUpMessageBody,
          isWithOptions: this.withOptions,
          actionsOptions: this.options,
          teamId: null,
          teamAvatarDir: 'https://lgame.gg/uploads/teams/avatars/',
          customFields_emptyInputs: {},
          selectedTeam: undefined,
          pollAnswer: null,
        }
    },
    watch:{
        withOptions(newVal, oldVal){
            this.isWithOptions = newVal;
        },
        options(newVal, oldVal){
            this.actionsOptions = {...newVal};
        },
        title(newVal, oldVal){
            this.popUpTitle = newVal;
        },
        popUpMessageBody(newVal, oldVal){
            this.messageBody = newVal;
        }
    },
    computed: {
      storeModule:{
        get(){
          return this.popUpType == "teams" ? this.$store.state.currentTournamentLol : this.$store.state.currentTournament;
        }
      },
      tournamentId:{
        get(){
          return this.storeModule.tournamentItem.id;
        }
      },
        hasCustomFields: {
            get() {
                if(this.tournamentLoaded){
                    const customFields = this.storeModule.tournamentItem.settings.custom_fields;
                    return customFields !== null && customFields.length > 0;
                }
                return false;
            }
        },
        customFields: {
            get() {
                if(this.tournamentLoaded){
                    return this.storeModule.tournamentItem.settings.custom_fields ?
                    this.storeModule.tournamentItem.settings.custom_fields.split(',') : null;
                }
                return null;
            }
        },
        currentPlayerTeams: {
            get() {
                if(this.tournamentLoaded){
                    return this.$store.state.currentTournamentLol.playerTeams;
                }
              return null;
            }
        },
        fetchingTeams: {
            get() {
                if(this.tournamentLoaded){
                    return this.$store.state.currentTournamentLol.fetchingTeams;
                }
                return null;
            }
        },
        gameName:{
          get(){
            return this.storeModule.tournamentItem.game.short_name;
          }
        },
      customFieldsValue:{
        get(){
          if(this.hasCustomFields){
            const fieldsVals = {};
            if(this.selectedTeam && this.popUpType === "teams"){
              this.customFields.forEach(elt => {
                for (let i=1; i<=this.selectedTeam.size; i++){
                  fieldsVals[`${elt} P${i}`]= '';
                  this.customFields_emptyInputs[`${elt} P${i}`] = false;
                }
              });
            }
            if(this.popUpType === "custom"){
              this.customFields.forEach(field => {
                this.customFields_emptyInputs[field] = false;
                fieldsVals[field] = '';
              });
            }
            return fieldsVals;
          }
          return {}
        },
        set(val){
          return val
        }
      },
        tournamentLoaded:{
            get() {
                return !!this.storeModule.tournamentItem;
            }
        },

    },
    methods: {
        executeAction() {
            this.$emit('executeAction');
            this.resetVars();
        },
      acceptAction(){
        console.log('action accepted: proceed');
        if(this.showCustomFields && this.popUpType === 'teams'){
          if(this.verifyCustomFields()){
            let customFields = {};
            if(this.tournamentId === 130){ // change this
              customFields = {...this.customFieldsValue, pollAnswer: this.pollAnswer==="Autres" ? this.pollCustomAnswer : this.pollAnswer};
            }
            this.$emit('acceptAction', {
              hasCustomFields: true,
              customFieldsValues: Object.keys(customFields).length > 0 ? customFields : this.customFieldsValue,
              teamId: this.teamId
            });
            this.$refs.participateBtnClose.click();
          }
          console.log('verify custom fields ...')
          console.log(this.verifyCustomFields())
        }
        else if(!this.showCustomFields && this.popUpType === 'teams'){
          this.$emit('acceptAction', {
            hasCustomFields: false,
            teamId: this.teamId
          });
          this.$refs.participateBtnClose.click();
        }
        else if(this.hasCustomFields && this.popUpType === 'custom'){
          if(this.verifyCustomFields()){
            this.$emit('acceptAction', {
              hasCustomFields: true,
              customFieldsValues: this.customFieldsValue,
            });
            this.$refs.participateBtnClose.click();
          }
        }
        else{
          this.$emit('acceptAction');
          this.$refs.participateBtnClose.click();
        }

      },
      abortAction(){
        console.log('action aborted: cancel');
        this.$emit('abortAction');
        this.resetVars();
        this.$refs.participateBtnClose.click();
      },
      closeModal(){
        this.$refs.participateBtnClose.click();
        console.log('modal clicked');
        this.resetVars();
        this.$emit('modal-closed');
      },
      resetVars(){
        this.teamId = null;
        this.showCustomFields = false;
        this.customFieldsValue = {};
        this.customFields_emptyInputs = {};
        this.pollCustomAnswer = "";
        this.pollAnswer = "";
      },
      getRGBA(color, opacity=0.8) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
      },
      teamParticipate(team){
        if(this.hasCustomFields){
          this.selectedTeam = team;
          //this.initCustomFields();
          this.popUpTitle = "informations supplémentaire";
          this.messageBody = "Veuillez fournir les informations suivantes pour compléter l'inscription de votre équipe";
          this.isWithOptions = true;
          this.actionsOptions = {
            mainAction: 'Participer',
            secondAction: 'Annuler'
          };
          this.teamId = team.id;
          this.showCustomFields = true;
        }else{
          this.$store.dispatch('currentTournamentLol/setParticipantTeam', {
            teamId: team.id
          }).then(() => {
            this.$store.dispatch('currentTournamentLol/triggerLoading', false);
            this.$refs.participateBtnClose.click();
          });
        }
      },
      verifyCustomFields(){
        for(let field in this.customFieldsValue){
          this.customFields_emptyInputs[field] = this.customFieldsValue[field] === '';
          console.log(this.customFields_emptyInputs[field])
        }
        // for xiaomi tournament
        if (this.pollAnswer === "") {
          this.customFields_emptyInputs['pollAnswer'] = true;
        }
        if (this.pollAnswer === "Autres" && this.pollCustomAnswer === "") {
          this.customFields_emptyInputs["customAnswer"] = true;
        }
        // end
        this.$forceUpdate();
        for(let field in this.customFields_emptyInputs){
          if(this.customFields_emptyInputs[field] === true) return false;
        }
        // for xiaomi tournament
        if(this.pollAnswer === ""){
          return false;
        }
        if(this.pollAnswer === "Autres" && this.pollCustomAnswer === ""){
          return false;
        }
        // end
        return true;
      },
      fieldFocusIn(field){
        this.customFields_emptyInputs[field] = false;
        this.$forceUpdate();
      },
      addNewTeam(){
        window.location = "https://player.lgame.gg/teams/ajouter"
      },
      imageNotFound(e){
        e.target.src = "https://lgame.gg/images/avatar-error-placeholder.png";
      },
      initCustomFields(){
        if(this.hasCustomFields && this.popUpType === 'teams'){
          this.customFieldsValue = {}
          Object.assign(this.customFieldsValue, {});
          Object.assign(this.customFields_emptyInputs, {});
          this.customFields.forEach(field => {
            for(let i = 0; i<0; i<this.selectedTeam.size){
              this.customFields_emptyInputs[field+i] = false;
              this.customFieldsValue[field+i] = '';
            }
          });
          this.$forceUpdate();
        }
      }
    },
    created() {
        console.log('participant pop up created ....');
        /*if (this.customFields && this.customFields.length > 0) {
            this.customFields.forEach(field => {
                this.customFields_emptyInputs[field] = false;
                this.customFieldsValue[field] = '';
            });
        }*/
    }
}
</script>

<style scoped>
.modal{
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(3px);
}

.modal-content{
  border-radius: unset;
}
.modal-dialog{
  /*width: 45vw !important;
  min-width: 580px !important;
  max-width: none;*/
  max-width: 600px;
}
.lgame-participation-pop-up-container-wrapper{

}
.lgame-participation-pop-up-content-wrapper{
  padding: 12px 12px 4px 4px;
  /*padding: 6px 6px 2px 2px;*/
  clip-path: polygon(0 8px, 55% 8px, calc(55% + 8px) 0,  calc(100% - 14px) 0, 100% 14px, 100% 46%, calc(100% - 8px) calc(46% + 8px), calc(100% - 8px) 100%, 12px 100%, 0 calc(100% - 12px));
  /*clip-path: polygon(0 8px, 55% 8px, calc(55% + 8px) 0,  calc(100% - 14px) 0, 100% 14px, 100% 46%, calc(100% - 8px) calc(46% + 8px), calc(100% - 8px) 100%, 12px 100%, 0 calc(100% - 12px));
  /*polygon(0 5px, 70% 5px, calc(70% + 5px) 0,  calc(100% - 10px) 0, 100% 10px, 100% 40%, calc(100% - 5px) calc(40% + 8px), calc(100% - 5px) 100%, 10px 100%, 0 calc(100% - 10px))*/
}
.lgame-participation-pop-up-content{
  color: #C4C4C4;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: #010D11;
  clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
  width: 100%;
  height: 90%;
}
.pop-up-title{
  font-family:"Roboto", sans-serif;
  font-weight: 600;
  font-size: 2rem;
}
.pop-up-title::first-letter{
  text-transform: capitalize;
}
.horizontal-line{
  width: 100%;
  height: 0.5pt;
}
.pop-up-button{
  color: white;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12pt;
  border: unset;
  border-radius: 0;
  outline: none;
  border: none;
  width: fit-content;
  clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%,0 100%, 0 8px);
}
.pop-up-button:hover{
  color: white;
  transform:scale(1.01);
}
.pop-up-button:active{
  color: white;
  transform: scale(1.01);
}
.pop-up-button:focus{
  outline: none;
}
.pop-up-button-cancel{
  border: none;
  outline: none;
  color: #C4C4C4;
  background:transparent;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12pt;
}
.pop-up-button-cancel:hover{
  color: white;
  transform:scale(1.01);
}
.custom-field-label{
  color: white;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}
.custom-field-input-container{
  filter: drop-shadow(0 0 2px rgba(0,0,0,0.4));
}
.custom-field-input{
  color: #eeeeee;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  border: unset;
  border-radius: 0;
  height: 30px;
  background-color: #00171F;
  border: unset;
  outline: none;
  width: 60%;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}
.custom-field-input:active,.custom-field-input:focus{
  outline: none;
  border: none;
  background-color: rgba(255, 0, 0,0.2);
}
.player-teams-container{
  height: 360px;
  overflow-y:auto;
}
.player-team-item-container{
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.8));
}
.player-team-item-container:hover{
  transform: translateX(5px);
  cursor: pointer;
}
.text-participate-by{
  display: none;
}
.player-team-item:hover .text-participate-by{
  display: block;
}
.player-team-item{
  height: 72px;
  background-color: #00171F;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}
.team-item-name{
  color: white;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.team-item-btn-participate{
  width:72px;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
}
.team-item-btn-participate:focus, .team-item-btn-participate:active{
  outline: none;
  border: none;
}
.team-item-btn-participate:hover{
  background-color: rgba(255, 0, 0, 0.8) !important;
}
.team-item-btn-participate img svg{
  fill: red !important;
}
.span-error{
  color: rgba(255, 0, 0, 0.8);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
.btn-new-team{
  background-color: #FF0000 !important;
}

@media only screen and (max-width: 768px){
  .modal-dialog{
    width: 90vw !important;
    max-width: 600px;
    min-width: unset !important;
    height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  }
  .modal-content{

  }
  .lgame-participation-pop-up-content{
    clip-path: polygon(0 0, calc(100% - 6px) 0, 100% 6px, 100% 100%, 8px 100%, 0 calc(100% - 8px));
  }
  .pop-up-button, .pop-up-button-cancel{
    letter-spacing: normal;
    font-size: 10pt;
    clip-path: polygon(6px 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%,0 100%, 0 6px);
  }
  .player-teams-container{
    height: 260px;
  }
  .player-team-item:hover .text-participate-by{
    display: none;
  }
  .custom-field-input{
    width: 100%;
    max-width: 280px;
  }

}
/* for xiaomi tournament*/
.poll-container{
  background-color:#00171F;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);

}
.poll-container .custom-field-input{
  background-color: #010D11 !important;
}
</style>
