<template>
    <div>
        <script type="application/javascript" defer src="https://player.twitch.tv/js/embed/v1.js" ref="twitchScript"></script>

        <!-- Lgame NavBar -->
        <LgameNavBar></LgameNavBar>
        <div class="main-page-wrapper">

            <div class="main-page-landing-area"
            >   
                <div class="hero-section-content-wrapper d-flex flex-column justify-content-center justify-content-lg-center align-items-start align-items-lg-center w-100 h-100"
                    :style="{
                        backgroundImage: `url(${require('@/assets/hero_bg.png')})`,
                        backgroundSize: '100% 100%'
                    }"
                >
                    <div class="d-flex flex-column flex-md-row container justify-content-center justify-content-md-between align-items-center p-0 w-100">
                        <div class="hero-section-content text-center container py-2 py-md-5">
                            <img class="mb-md-4" :src="require('@/assets/ellectro-s2-logo.png')" width="580px"/>
                            <h1 class="text-white text-center text-justify text-slogan">GIRLS, GAME<br>AND GLORY !</h1>
                            <p class="text-white text-center text-justify cta-paragraph">Participer À Notre Événement Gaming<br>
                                100% Féminin
                            </p>
                            <div class="landing-content w-100 text-center m-lg-0 px-0 d-none d-md-block">
                                <div class="card-participate">
                                    <a href="#participate" class="participate-btn px-5 py-1" width="75%">Participer</a>
                                </div>
                            </div>
                        </div>
                        <div class="hero-ui-elts ml-0 ml-md-5 w-100 text-center mb-5 mb-md-0 ">
                            <img :src="require('@/assets/hero-ui-elts.png')" width="100%"/>
                        </div>
                    </div>
                    <div class="landing-content w-100 text-center m-lg-0 px-0 mb-5 d-md-none w-100 text-center">
                        <div class="card-participate">
                            <a href="#participate" class="participate-btn px-5 py-1" width="75%">Participer</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cta-section-wrapper px-4 pt-5"  id="participate"
                :style="{
                    backgroundImage: `url(${require('@/assets/cta-background.png')})`,
                    backgroundSize: '100% 80%'
                }"
            >
                <div class="cta-section-content d-flex justify-content-center align-items-start align-items-lg-center container mb-md-5">
                    <div class="p-0 mb-2 mb-md-0 mr-2 mr-md-0 p-md-5">
                        <img :src="require('@/assets/games.png')" width="380px"/>
                    </div>
                    <div class="ml-lg-5 w-100">
                        <h2 class="section-title text-left text-uppercase">Attention à toutes<br>
                            les gameuses!
                        </h2>
                        <p class="text-justify about-text">
                            Venez mettre en avant vos compétences et participez à notre compétition de League of legends & EA FC 24 spécialement conçue pour vous ! Peu importe votre niveau d'expérience, que vous soyez une experte en la matière ou que vous débutiez tout juste, vous aurez l'opportunité d'affronter d'autres joueuses dans un environnement professionnel au sein de E-blue gaming center.<br>Rendez-vous sur place pour prendre part à cette compétition passionnante !
                        </p>
                        <div>
                            <p class="text-justify about-text">
                                 Découvrez comment créer votre propre équipe sur le <a href="https://www.youtube.com/watch?v=O-0hvV8WwUY">lien suivant</a> 
                            </p>
                        </div>
                        <div class="card-participate d-flex flex-column flex-md-row justify-content-between w-auto">
                            <ParticipateCard class=""
                                @init-pop-up="initPopUp"
                                @view-bracket="viewBracket"
                                game="fc24"
                                :game-id="eafc24_tournament_id"
                            ></ParticipateCard>
                            <ParticipateCard class="mt-2 mt-md-0 ml-0 ml-md-3"
                                @init-pop-up="initPopUp"
                                @view-bracket="viewBracket"
                                game="lol"
                                :game-id="lol_tournament_id"
                            ></ParticipateCard>
                        </div>
                    </div>
                </div>


                <div class="lieu-date-wrapper container p-0 mt-5 pt-5">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <div class="mr-5">
                            <h2 class="section-title text-left">Lieu et date:</h2>
                            <p class="lieu-date-paragraph text-justify">
                                Notre événement 100% féminin se déroulera le 09 mars
                                dés 13h dans le E-blue gaming center.
                            </p>
                            <div class="location-icon-container d-flex align-items-center">
                                <img class="location-icon" :src="require('@/assets/location-icon.png')" width="24px"/>
                                <h6 class="map-location m-0 ml-2 text-underline">
                                    <a href="https://www.google.com/maps/place/E-Blue+Gaming+Center/@33.5925209,-7.6372703,17z/data=!3m1!4b1!4m6!3m5!1s0xda7d3ff7b4c6fcb:0x6b88d14874c6553e!8m2!3d33.5925209!4d-7.6372703!16s%2Fg%2F11qz_rtx3v?entry=ttu" target="_blank">
                                        La localisation: E-blue gaming center, Casablanca
                                    </a>
                                </h6>
                            </div>
                        </div>
                        <div class="magazin-electro-container ">
                            <div class="px-5 px-md-0 my-2 my-md-0 w-100">
                                <img class="magazin-electro" :src="require('@/assets/eblue.png')" width="380px"/>
                            </div>
                            <div class="location-icon-container d-flex align-items-center">
                                <img class="location-icon" :src="require('@/assets/location-icon.png')" width="24px"/>
                                <h6 class="map-location m-0 ml-2 text-underline">
                                    <a href="https://www.google.com/maps/place/E-Blue+Gaming+Center/@33.5925209,-7.6372703,17z/data=!3m1!4b1!4m6!3m5!1s0xda7d3ff7b4c6fcb:0x6b88d14874c6553e!8m2!3d33.5925209!4d-7.6372703!16s%2Fg%2F11qz_rtx3v?entry=ttu" target="_blank">
                                        La localisation: E-blue gaming center, Casablanca
                                    </a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="prizes-container container mt-5 my-lg-5 p-lg-0 w-100">
                <h2 class="section-title text-left">Prize pool:</h2>
                <div class=" d-flex justify-content-between align-items-center p-2">
                    <div>
                        <img class="valo-agent" :src="require('@/assets/prix-ui.png')" width="460px"/>
                    </div>
                    <div class="">
                        <h4 class="prize-value text-uppercase" style="color:#910b28; font-family: monument_extendedultrabold;">League of legends:</h4>
                        <div class="gold-prize-container d-flex justify-content-between align-items-center">
                            <div>
                                <img class="gold-prize-icon" :src="require('@/assets/gold-trophy.png')" width="80px"/>
                            </div>
                            <div class="ml-2">
                                <h3 class="prize-title text-uppercase m-0" style="color: #E6AE04 !important;">Première place<br>– </h3>
                                <h5 class="prize-value">20 000 DHS* </h5>
                            </div>
                        </div>
                        <div class="silver-prize-container d-flex justify-content-between align-items-center ml-2 ml-lg-4 my-2 mb-5">
                            <div>
                                <img class="silver-prize-icon" :src="require('@/assets/silver-trophy.png')" width="60px"/>
                            </div>
                            <div class="ml-3">
                                <h3 class="prize-title text-uppercase m-0" style="color: #C9C9C9 !important;">Deuxième place<br>– </h3>
                                <h5 class="prize-value">5 000 DHS*</h5>
                            </div>
                        </div>
                        <h4 class="prize-value text-uppercase" style="color:#910b28; font-family: monument_extendedultrabold;">EA FC24:</h4>
                        <div class="gold-prize-container d-flex justify-content-between align-items-center">
                            <div>
                                <img class="gold-prize-icon" :src="require('@/assets/gold-trophy.png')" width="80px"/>
                            </div>
                            <div class="ml-2">
                                <h3 class="prize-title text-uppercase m-0" style="color: #E6AE04 !important;">Première place<br>– </h3>
                                <h5 class="prize-value">7 000 DHS* </h5>
                            </div>
                        </div>
                        <div class="silver-prize-container d-flex justify-content-between align-items-center ml-2 ml-lg-4 my-2 ">
                            <div>
                                <img class="silver-prize-icon" :src="require('@/assets/silver-trophy.png')" width="60px"/>
                            </div>
                            <div class="ml-3">
                                <h3 class="prize-title text-uppercase m-0" style="color: #C9C9C9 !important;">Deuxième place<br>– </h3>
                                <h5 class="prize-value">3 000 DHS*</h5>
                            </div>
                        </div>
                        <span class="prize-title desc-prize-label">* En bon d'achat Electroplanet</span>
                    </div>
                </div>
            </div>
            <div class="stream-wrapper py-lg-5 mt-5"
            >
                <div class="stream-container container my-3 my-lg-5 p-lg-0 py-5 w-100" id="stream"
                >
                    <h2 class="section-title text-left mt-lg-5">Stream de l'événement:</h2>
                    <div class="stream" ref="twitchPlayer"></div>
                </div>
            </div>
        </div>
        <div class="faq-container container my-3 my-lg-5 py-4 w-100">
            <FaqList/>
        </div>
        <div class="mb-md-5"></div>
        <!-- lgame footer -->
        <LgameFooter></LgameFooter>

        <!-- Lgame participate pop up-->
        <LgameParticipatePopUp
            :pop-up-type="popUpOptions.type"
            :title="popUpOptions.title"
            :pop-up-message-body="popUpOptions.message"
            :with-options="popUpOptions.withOptions"
            :options="popUpOptions.options"
            :game="popUpOptions.game"
            v-if="showPopUp"
            @modal-closed="abortAction"
            @acceptAction="acceptAction"
            @abortAction="abortAction"
        ></LgameParticipatePopUp>

    </div>
</template>

<script>

    import ParticipateCard from './ParticipateCard.vue';
    import LgameFooter from './LgameFooter.vue';
    import LgameNavBar from './NavBar.vue';
    import LgameParticipatePopUp from './LgameParticipatePopUp.vue';
    import axios from "axios";
    import eventConst from "@/assets/const/const";
    import FaqList from './FAQ/FaqList.vue';

    export default{
        name: "MainPage",
        components:{
            ParticipateCard,
            LgameParticipatePopUp,
            LgameNavBar,
            LgameFooter,
            FaqList,
        },
        data(){
            return{
                twitchPlayer: null,
                playerLoaded: false,

                showPopUp: false,
                popUpOptions: {
                    type: '',
                    title: '',
                    message: '',
                },
            }
        },
        computed:{
          lol_tournament_id:{
            get(){
              return eventConst.lol_tournament_id//process.env.VUE_APP_LOL_TOURNAMENT_ID;
            }
          },
          eafc24_tournament_id:{
            get(){
              return eventConst.eafc_tournament_id//process.env.VUE_APP_EA_FC_TOURNAMENT_ID;
            }
          },

          isLoaded: {
              get(){
                  return !!this.currentTournament;
              },
              set(val){
                  return val;
              }
          },
          currentTournament: {
              get() {
                  return this.$store.state.currentTournament.tournamentItem ? this.$store.state.currentTournament.tournamentItem : null;
              }
          },
        },
        methods: {
            initPlayer(){
                // eslint-disable-next-line
                this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                    width: '100%',
                    height: '100%',
                    channel: 'electroplanet_maroc',
                });
                this.twitchPlayer.setVolume(0.5);
                this.playerLoaded = true
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                   
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: true,
                    })*/
                });
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {
                    
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: false,
                    })*/
                });   
            },
            acceptAction(payload) {
                this.showPopUp = false;
                
                if (payload && payload.hasCustomFields) {
                    if (payload.teamId) {
                        this.$store.dispatch('currentTournamentLol/setParticipantTeam', {
                            teamId: payload.teamId,
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournamentLol/triggerLoading', false);
                        });
                    }
                    else {
                        this.$store.dispatch(this.popUpOptions.popUpDispatch, {
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                } else {
                    if (payload && payload.teamId) {
                        this.$store.dispatch('currentTournamentLol/setParticipantTeam', {
                            teamId: payload.teamId
                        }).then(() => {
                            this.$store.dispatch('currentTournamentLol/triggerLoading', false);
                        });
                    }
                    else {
                        console.log('payload from pop up action ================= ')
                        console.log(payload)
                        console.log('payload from pop up action ================= ')
                        if (this.popUpOptions.dispatchData) {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        } else {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                                this.$store.dispatch('currentTournament/triggerLoading', false);
                            });
                        }
                    }
                }
            },
            abortAction() {
                this.showPopUp = false;
                this.$store.dispatch('currentTournament/triggerLoading', false);
            },
            initPopUp(options) {
                this.popUpOptions = options;
                this.showPopUp = true;
            },
             viewBracket(){
                this.$refs.bracketTrigger.click();
            },
        },
        created(){
            this.$store.dispatch('currentUser/authCheck').then(response => {
                if (response.success) {
                    this.$store.dispatch('currentUser/setCurrentUser');
                }
            });
           this.$store.dispatch('currentTournament/getTournament', this.eafc24_tournament_id);
           this.$store.dispatch('currentTournamentLol/getTournament', this.lol_tournament_id);
        },
        mounted() {
            const resizeOps = () => {
                document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
            };

            resizeOps();
            window.addEventListener("resize", resizeOps);

           this.$refs['twitchScript'].onload = () => {
                this.initPlayer();
            }
        }
    }

</script>

<style scoped>
.main-page-landing-area{
    min-height: calc(var(--vh, 1vh) * 100);
    background: rgb(33,24,62);
    background: linear-gradient(90deg, rgba(33,24,62,1) 1%, rgba(72,22,65,1) 20%, rgba(128,17,76,1) 47%, rgba(228,0,64,1) 72%, rgba(228,0,64,1) 100%);
}
.hero-section-content-wrapper{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: cover;
}
.cta-section-wrapper{
   
    background-repeat: no-repeat;
    background-size: cover;
}
.text-slogan{
    font-family: 'monument_extendedregular', sans-serif;
    font-weight: 500;
    font-size: 3.2rem;
}
.cta-paragraph{
    font-family: 'grupporegular', sans-serif;
    text-transform: capitalize;
    font-size:1.4rem;
}
.participate-btn{
    border:unset;
    background: #E30923;
    outline: none;
    color: white;
    background-size: 100% 100%;
    height: 42px;
    font-family: 'Gemunu Libre Bold';
    font-size: 20pt;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    width: 75%;
}
.participate-btn:hover{
    transform: translateX(1px);
    transition: scale .1s linear;
}
.about-text{
    color: #7A237B;
    font-family:'Gemunu Libre Bold', sans-serif;
    font-size: 1.4rem;
}
.cta-section-content .section-title{
    font-size: 40pt;
    line-height: 1;
}
.cta-section-content div img{
    margin-left: -32px;
}
.lieu-date-paragraph{
    color: #7A237B;
    font-family:'Gemunu Libre Bold', sans-serif;
     font-size: 1.4rem;
}
.map-location a{
     color: #7A237B;
    font-family:'Gemunu Libre Bold', sans-serif;
     font-size: 1.4rem;
   text-decoration: underline;
}
.prize-title{
    color: #501D77;
    font-family: 'monument_extendedultrabold',sans-serif;
}
.desc-prize-label{
    font-size: 10pt;
    font-family: 'monument_extendedregular',sans-serif;
}
.prize-value{
    color: #7A237B;
    font-family: 'monument_extendedregular',sans-serif;
    font-size: 1rem;
}
.section-title{
    color:#E62A41 !important;
    font-family:'Gemunu Libre ExtraBold', sans-serif;
    font-size: 46pt;
}
.stream-wrapper{
     background-repeat: no-repeat;
    background-size: cover;
}
.stream{
    height: 600px;
    background-color:#910b287d;
}
.bracket{
    min-height: 860px;
    background-color: #910b287d;
    overflow: hidden;
}
.magazin-electro-container .location-icon-container{
    display: none !important;
}

@media screen and (max-width: 800px){
    .main-page-landing-area{
        background-size: cover;
    }
}

@media screen and (max-width: 768px) {
  
    .hero-ui-elts img{
        width: 70%;
    }
    .section-title{
        font-size: 36pt;
    }
    .stream{
        width: 100% !important;
        height: 360px;
    }
    .hero-section-content-wrapper{
        background-position: bottom right;
    }
    .hero-section-content img{
        width: 80vw;
        max-width: 380px;
        min-width: 300px;
        
    }
    .text-slogan{
        font-size: 2rem;
    }
    .cta-paragraph{
        font-size:1rem;
    }
    .cta-section-content div img{
        margin-left: 0px;
        width: 80px !important;
    }
    .cta-section-content .section-title{
        font-size: 20pt;
    }
    .about-text{
    font-size: 1rem;
    margin-left: -80px;
    margin-top: 1.6rem;
    }
    .cta-section-content .card-participate{
        margin-left: -80px;
    }
    .section-title{
        font-size: 24pt;
    }
    .magazin-electro{
        width: 100%;
    }
    .lieu-date-paragraph{
        font-size:1rem;
    }
    .locatio-icon{
        width: 16px;
    }
    .map-location a{
        font-size: 12pt;
    }
    .valo-agent{
        width: 120px;
    }
    .gold-prize-icon{
        width: 60px;
    }
    .silver-prize-icon{
        width:46px;
    }
    .prize-title{
        font-size: 14pt;
    }
    .desc-prize-label{
        font-size: 8pt;
    }
    .prize-value{
        font-size: 10pt;
    }
    .lieu-date-wrapper .location-icon-container{
        display: none !important;
    }
    .magazin-electro-container .location-icon-container{
        display: flex !important;
    }
}
</style>