import Vue from 'vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import store from './store';
import axios from 'axios';
axios.defaults.withCredentials=true;

Vue.config.productionTip = false;

var DEBUG = true;
// ENABLE/DISABLE Console Logs
if(!DEBUG){
  console.log = function() {}
}

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
